@use "../../../../react-components/styles/theme.scss";
@import "../../../../assets/stylesheets/shared.scss";

:local(.ui) {
  @extend %default-font;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}

:local(.quiz-container) {
  width: 500px;
  position: absolute;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 600px) {
    width: 95%;
    padding: 0 12px;
    // transform: translateY(-60px);
  }

  &.statue {
    :local(.present-text) {
      margin-top: 0;
    }
  }
}

:local(.link) {
  color: #ff4c00;
  &:hover {
    color: #ff4c00;
  }
}

:local(.promocode-block) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 48px;
  gap: 20px;
  user-select: none; // Prevent accidental user selection

  position: absolute;
  width: 500px;

  background: rgba(36, 56, 73, 0.8);
  /* Yel */
  box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 42px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }

  :local(.close-btn) {
    right: -44px;
    top: -7%;

    padding-left: 20px;
    padding-top: 14px;
    @media screen and (max-width: 600px) {
      right: -12px;
      top: -10%;
    }
  }
}

:local(.litres-img) {
  position: relative;
  z-index: 10;
  top: -96px;
  @media screen and (max-width: 600px) {
    top: -81px;
  }
}

:local(.present-text) {
  margin-top: -80px;
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 100%;
  text-align: center;

  color: #ffffff;
}

:local(.promo-input) {
  height: 48px;
  border: 1px solid #dadee7;
  border-radius: 8px;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  text-align: center;
  width: 90%;
  color: #ff4c00;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    width: 100%;
  }
}

:local(.descr-text) {
  font-weight: 400;
  font-size: 20px;
  line-height: 104.9%;
  color: #ffffff;
}
:local(.full-descr) {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  color: #ffffff;
}

:local(.link-btn) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 44px;

  background: #ff4c00;
  border-radius: 32px;
  padding: 8px 24px;

  font-weight: 400;
  font-size: 18px;
  line-height: 17px;

  color: #ffffff;
}

:local(.icon-question) {
  position: absolute;
  // left: -19px;
  // top: 111px;
  top: 0;
  left: 0;
  transform: translate(-37%, -35%);
  z-index: 10;
  height: 135px;
  width: 90px;
  user-select: none; // Prevent accidental text selection
}

:local(.icon-question-zvuk) {
  position: absolute;
  left: 311px;
  top: 202px;
  z-index: 10;
  height: 135px;
  width: 90px;

  @media screen and (max-width: 600px) {
    left: 214px;
  }
}

:local(.closeBtn) {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(100%, -100%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

:local(.closeBtnFiller) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
  transition: opacity 0.35s linear;
}

:local(.closeBtnFillerDefault) {
  z-index: 1;
  opacity: 1;
}

:local(.closeBtnFillerActive) {
  z-index: 2;
  opacity: 0;
}

:local(.closeBtn):hover,
:local(.closeBtn):focus {
  background: transparent;
  border: none;
  outline: none;

  :local(.closeBtnFillerDefault) {
    opacity: 0;
  }

  :local(.closeBtnFillerActive) {
    opacity: 1;
  }
}

:local(.out) {
  background: rgba(255, 255, 255, 0);
}
:local(.close-btn) {
  // border-radius: 24px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  // background: rgba(38, 62, 84, 0.8);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 19px 38px rgba(33, 38, 44, 0.15);
  // backdrop-filter: blur(6px);
  // padding-left: 18px;
  // padding-top: 10px;
  z-index: 100;
  // color: #ffffff;
  user-select: none; // Prevent accidental text selection

  height: 32px;
  width: 32px;
  fill: rgba(38, 62, 84, 0.8);

  .border,
  .stroke {
    stroke: #ffdf67;
  }
}

:local(.quiz-title) {
  padding: 48px;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  color: #c7ba7c;
  background: rgb(87, 87, 87);
  background: -moz-linear-gradient(90deg, rgba(59, 59, 59, 0.87) 0%, rgba(153, 153, 153, 0.92) 100%);
  background: -webkit-linear-gradient(90deg, rgba(59, 59, 59, 0.87) 0%, rgba(153, 153, 153, 0.92) 100%);
  background: linear-gradient(90deg, rgba(59, 59, 59, 0.87) 0%, rgba(153, 153, 153, 0.92) 100%);
  border: 3px solid #ffdf67;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 42px;
  user-select: none; // Prevent accidental text selection
}

:local(.quiz-title-zvuk) {
  padding: 48px;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  color: #fff;
  background: rgba(6, 8, 97, 0.8);
  border: 3px solid #0046cf;
  backdrop-filter: blur(10px);
  border-radius: 42px;
  padding-top: 100px;
  width: 100%;
}

:local(.quiz-counter-container) { 

  @media (max-width: 768px) {
    margin: 0 10px; }  
    @media (max-width: 900px) {
      margin: 0 60px; }  
    @media (max-width: 1040px) {
      margin: 0 80px; }  
  
  flex: none;
  margin: 0 132px;
  // position: fixed;
  // top: 38px;
  // background: rgba(38, 62, 84, 0.8);
  background-image: url("../images/icon_que.png");
  background-size: 100% 100%;
  height: 72px;
  width: 184px;
  // margin-left: 125px;
  cursor: pointer;
 
}

// :local(.quiz-counter-containe) {
//   background-image: url("../images/icon_que.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   position: relative;
//   width: 189px;
//   height: 77px;
//   // cursor: pointer;
// }


// /home/ksu/www/sberlab/sberlab-hubs-docker/hubs/hubs/src/custom/Quiz/assets/images/icon_que.png





:local(.icon-quiz) {
 // background: no-repeat 8px center url("../../../../assets/images/custom/Question.svg");
  height: 64px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
  user-select: none; // Prevent accidental text selection
}

:local(.counter-quiz) {
  font-family: "SB Sans Interface", sans-serif;

  font-size: 20px;
  color: #ffffff;
  line-height: 24.88px;
  padding-top: 18px;
  padding-right: 32px;
  letter-spacing: 2px;
}
:local(.curr-quiz) {
  font-weight: 600;
}

:local(.answers-container) {
  width: 100%;
  user-select: none; // Prevent accidental text selection

  & > button {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    border-radius: 24px;
    padding: 16px;
    text-align: center;
    font-family: "Friday Condensed";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    color: #000000;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: inherit;
      pointer-events: none;
      z-index: -1;
      transform: translate(3px, 3px);
      background-color: #9e7831;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: inherit;
      pointer-events: none;
      z-index: -1;
      transform: translate(-3px, -3px);
    }

    :local(.answer-overlay) {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: inherit;
      pointer-events: none;
      z-index: -1;
      transform: translate(-3px, -3px);
      background-image: linear-gradient(90deg, #ca9c47 0%, #f3dc60 100%);
    }
  }

  & > * + * {
    margin-top: 20px;
  }
}

:local(.answer-base) {
  backdrop-filter: blur(10px);
  border-radius: 42px;
  text-align: center;
}

:local(.answer-zvuk) {
  color: #fff;
  background: rgba(6, 8, 97, 0.8);
  border: 3px solid #0046cf;
  &:hover {
    cursor: pointer;
    font-weight: 600;
    background: rgba(9, 11, 161, 0.8);
    border: 3px solid #0046cf;
  }
}

:local(.answer-default) {
  &::after {
    // content: "";
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // border-radius: inherit;
    // pointer-events: none;
    // z-index: -1;
    // transform: translate(-3px, -3px);
    opacity: 0;
    transition: opacity 0.35s ease-out;
    background-image: linear-gradient(-68deg, #ca9c47 0%, #f6e47f 100%);
  }

  &:hover,
  &:focus {
    &::after {
      opacity: 1;
    }
  }
}

:local(.answer-true) {
  &::after {
    // content: "";
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // border-radius: inherit;
    // pointer-events: none;
    // z-index: -1;
    // transform: translate(-3px, -3px);
    background-image: linear-gradient(-68deg, #43ce24 0%, #f6e47f 100%);
  }
}

:local(.answer-false) {
  &::after {
    // content: "";
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // border-radius: inherit;
    // pointer-events: none;
    // z-index: -1;
    // transform: translate(-3px, -3px);
    background-image: linear-gradient(-68deg, #ce5424 0%, #f6e47f 100%);
  }
}

:local(.modal-layer) {
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:local(.modal-wrapper) {
  width: 500px;
  max-height: 100vh;
  overflow: auto;
  padding: 25px;

  background: rgba(36, 56, 73, 0.8);
  box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);

  border-radius: 42px;
  @media screen and (max-width: 600px) {
    width: 95%;
  }
  user-select: none; // Prevent accidental text selection
}

:local(.modal-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

:local(.title) {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}

:local(.total) {
  font-size: 42px;
  font-weight: 400;
  margin: 24px 0;
  letter-spacing: 5px;
}

:local(.zvuk-logo) {
  position: relative;
  top: 110px;
  z-index: 100001;
  height: 68px;
  left: 50px;
}

:local(.search-quiz) {
  background-color: #ffffff;
  border-radius: 51.3266px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  width: 250px;
  text-align: center;
  height: 56px;
  padding-top: 9px;
  margin-top: 28px;
  color: black;
  cursor: pointer;
}
:local(.text) {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .icon-question {
    transform: translate(-25%, -35%);
    height: 100px;
    width: 70px;
  }

  :local(.quiz-title) {
    padding: 38px;
    font-size: 22px;
  }
}

@media screen and (max-width: 600px) {
  :local(.closeBtn) {
    transform: translate(0%, -100%);
  }
}

@media screen and (max-width: 360px) {
  .quiz-container {
    width: 98%;
  }
}
