@font-face {
  font-family: 'Friday Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("./Friday Condensed Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Friday Condensed';
  font-style: normal;
  font-weight: bold;
  src: url("./Friday Condensed Bold.ttf") format('truetype');
}