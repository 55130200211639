@use "../styles/theme.scss";

:local(.content) {
  align-items: center;
  padding: 24px;
  padding-top: 10px;
  text-align: center;
  margin: 25px;
}


:local(.avatar-preview-container) {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  /* TODO: This styling into AvatarPreview */
  & > :first-child {
    /*
    We need to set dimensions explicitly here, since AvatarPreview
    resizes itself to its container's dimensions.
    */
    width: 208px;
    height: 208px;
    border-radius: 50%;
    background-color: theme.$tile-bg-color;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  }

  button {
    position: absolute;
    bottom: 0;
    margin-bottom: 8px;
  }
}
