:local(.catsCollection) {
  background-image: url("../icons/imagesStatue/icon_bigstar.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 189px;
  height: 77px;
  cursor: pointer;
}




:local(.iconCat) {
  height: 100%;
  // background: no-repeat 8px center url("../icons/imagesStatue/Statue66064.png");
  background-size: contain;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
}

:local(.counterCat) {
  font-family: "SB Sans Interface", sans-serif;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 24.88px;
  padding-right: 15px;
  letter-spacing: 1px;
  position: absolute;
  right: 5px;
  bottom: 20px;
}
:local(.currCats) {
  font-weight: 600;
}