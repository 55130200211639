@import url('../fonts/FridayCondensed.css');

:local(.modal-window) {
	position: fixed;
	z-index: 999;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	user-select: none;

	& * {
		user-select: none;
	}
}

:local(.modal-window-frame) {
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	pointer-events: none;
}

:local(.top-right-angle) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: right top;
	background-size: cover;
}

:local(.bottom-left-angle) {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: cover;
}

:local(.big-frame) {
	position: absolute;
	left: -1.5%;
	right: -1.5%;
	top: -5.5%;
	bottom: -5.5%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
}

:local(.top-lightning) {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-12px, 60px);
	max-width: 165px;
	height: auto;
}

:local(.top-star) {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-56px, -29px);
	max-width: 100px;
}

:local(.bottom-lightning) {
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translate(15px, -102px);
	max-width: 100px;
}

:local(.bottom-star) {
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translate(26px, 38px);
	max-width: 140px;
}


:local(.close-btn) {
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	transform: translate(100%, -100%);
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

:local(.close-btn-filler) {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	pointer-events: none;
	transition: opacity 0.35s linear;
}

:local(.close-btn-filler-default) {
	z-index: 1;
	opacity: 1;
}

:local(.close-btn-filler-active) {
	z-index: 2;
	opacity: 0;
}

:local(.close-btn):hover,
:local(.close-btn):focus {
	background: transparent;
	border: none;
	outline: none;

	:local(.close-btn-filler-default) {
		opacity: 0;
	}

	:local(.close-btn-filler-active) {
		opacity: 1;
	}
}

:local(.modal-window-content) {
	padding: 200px;
	max-width: 80vw;
	max-height: 80vh;
}

:local(.modal-window-flex) {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

:local(.modal-window-text) {
	flex: 1 1 auto;
	margin: 0 auto;
	margin-bottom: 30px;
	width: max-content;
	max-width: 100%;
	text-align: center;
	color: #bfb184;
	font-family: 'Friday Condensed';
	font-size: 32px;
	line-height: 1.5;
	font-weight: 400;
}

:local(.modal-window-overlay) {
	position: absolute;
	z-index: -2;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 10%;
	background-color: #464646;
	pointer-events: none;
	overflow: hidden;
}

:local(.modal-window-overlay-glow) {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 600px;
	height: 600px;
	border-radius: 50%;
	background: rgb(149, 149, 149);
	background: -moz-radial-gradient(circle, rgba(149, 149, 149, 0.1) 0%, #464646 80%);
	background: -webkit-radial-gradient(circle, rgba(149, 149, 149, 0.1) 0%, #464646 80%);
	background: radial-gradient(circle, rgba(149, 149, 149, 0.1) 0%, #464646 80%);
}

@media screen and (max-width: 1300px) {

	:local(.modal-window-content) {
		padding: 120px;
	}

	:local(.top-lightning) {
		max-width: 85px;
	}

	:local(.top-star) {
		transform: translate(-31px, -29px);
		max-width: 77px;
	}

	:local(.bottom-star) {
		transform: translate(20px, 30px);
		max-width: 100px;
	}

	:local(.bottom-lightning) {
		transform: translate(27px, -77px);
		max-width: 70px;
	}
}

@media screen and (max-width: 965px) {
	:local(.close-btn) {
		transform: translate(50%, -150%);
	}

	:local(.top-lightning) {
		max-width: 55px;
		transform: translate(-13px, 62px);
	}

	:local(.top-star) {
		transform: translate(-16px, -24px);
		max-width: 62px;
	}

	:local(.bottom-star) {
		transform: translate(16px, 24px);
		max-width: 70px;
	}

	:local(.bottom-lightning) {
		transform: translate(22px, -68px);
		max-width: 58px;
	}

	:local(.modal-window-content) {
		padding: 125px 60px;
	}
}

@media screen and (max-width: 665px) {

	:local(.modal-window-content) {
		padding: 125px 25px;
		max-width: 85vw;
	}

	:local(.top-star) {
		transform: translate(-12px, -16px);
		max-width: 47px;
	}

	:local(.top-lightning) {
		max-width: 48px;
		transform: translate(-9px, 63px);
	}

	:local(.bottom-lightning) {
		transform: translate(1px, -64px);
	}

	:local(.bottom-star) {
		transform: translate(3px, 17px);
		max-width: 59px;
	}
}

@media screen and (max-width: 450px) {

	:local(.modal-window-content) {
		padding: 60px 20px;
	}

	:local(.top-lightning) {
		max-width: 35px;
		transform: translate(13px, 28px);
	}

	:local(.top-star) {
		transform: translate(-8px, -16px);
		max-width: 34px;
	}

	:local(.bottom-lightning) {
		transform: translate(-12px, -32px);
		max-width: 34px;
	}

	:local(.bottom-star) {
		max-width: 44px;
	}

	:local(.modal-window-text) {
		font-size: 20px;
	}
}
