@import url('./assets/fonts/FridayCondensed.css');

:local(.form-wrapper) {
	position: fixed;
	z-index: 999;
	left: 50%;
	top: 50%;
	width: 540px;
	max-height: 100vh;
	max-width: 100vw;
	transform: translate(-50%, -50%);
}

:local(.form-overlay) {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100vw;
	height: 100vh;
	display: block;
	z-index: -1;
	background-color: rgba(#000, 0.5);
}

:local(.form-entry) {
	position: relative;
	height: 0;
	padding-top: 160%;
}

:local(.form-entry-bg) {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	pointer-events: none;
	user-select: none;
}

:local(.submit-btn) {
	position: relative;
	border-radius: 24px;
	padding: 16px;
	text-align: center;
	font-family: "Friday Condensed";
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 1;
	text-transform: uppercase;
	white-space: nowrap;
	color: #000000;
	background: transparent;
	border: none;
	outline: none;
	transition: opacity 0.35s ease-out;
	cursor: pointer;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: inherit;
		pointer-events: none;
		z-index: -1;
		transform: translate(3px, 3px);
		background-color: #9e7831;
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: inherit;
		pointer-events: none;
		z-index: -1;
		transform: translate(-3px, -3px);
		background-image: linear-gradient(-68deg, #ca9c47 0%, #f3dc60 100%);
	}

	&:hover,
	&:focus {
		opacity: 0.7;
	}
}

:local(.form-entry-btn) {
	position: absolute;
	z-index: 1;
	left: 50%;
	bottom: 2%;
	transform: translate(-50%, 0);
}

:local(.close-btn) {
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	transform: translate(-100%, -25%);
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

:local(.close-btn-filler) {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	pointer-events: none;
	transition: opacity 0.35s linear;
}

:local(.close-btn-filler-default) {
	z-index: 1;
	opacity: 1;
}

:local(.close-btn-filler-active) {
	z-index: 2;
	opacity: 0;
}

:local(.close-btn):hover,
:local(.close-btn):focus {
	background: transparent;
	border: none;
	outline: none;

	:local(.close-btn-filler-default) {
		opacity: 0;
	}

	:local(.close-btn-filler-active) {
		opacity: 1;
	}
}

:local(.form) {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 410px;
	transform: translate(-50%, -15%);
	user-select: none;
}

:local(.submit-input)::-webkit-scrollbar {
	background-color: transparent;
	width: 10px !important;
}

:local(.submit-input)::-webkit-scrollbar-thumb {
	background-color: rgb(75, 75, 75);
	width: 10px !important;
	border-radius: 6px;
}

:local(.submit-input) {
	margin-bottom: 10px;
	padding: 10px 16px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	border: 2px solid #ca9b4b;
	outline: none;
	border-radius: 20px;
	color: #fff;
	font-family: 'Friday Condensed';
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4;
	resize: none;

	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
}

:local(.form-text) {
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 82%;
	color: #fff;
	font-family: 'Friday Condensed';
	font-weight: 400;
	text-align: center;
	font-size: 14px;
	line-height: 1.4;
}

:local(.form-note) {
	margin-bottom: 70px;
	color: #fff;
	font-family: 'Friday Condensed';
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4;
}


@media screen and (min-width: 768px) and (max-height: 900px) {
	:local(.form-entry) {
		padding-top: 117%;
	}

	:local(.form) {
		width: 310px;
	}

	:local(.form-note) {
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 768px) and (max-height: 700px) {
	:local(.form-entry) {
		padding-top: 106%;
	}

	:local(.form) {
		width: 295px;
	}

	:local(.form-note) {
		font-size: 13px;
	}
}

@media screen and (max-width: 768px) {
	:local(.form) {
		transform: translate(-50%, -24%);
	}
}

@media screen and (max-width: 600px) {
	:local(.form) {
		width: 310px;
	}
}

@media screen and (min-width: 455px) and (max-width: 768px) and (max-height: 770px) {
	:local(.form) {
		width: 280px;
	}

	:local(.form-note) {
		margin-bottom: 20px;
	}

	:local(.form-entry) {
		padding-top: 120%;
	}
}

@media screen and (min-width: 400px) and (max-width: 768px) and (max-height: 730px) {
	:local(.form-entry) {
		padding-top: 130%;
	}

	:local(.form) {
		width: 295px;
	}

	:local(.form-note) {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 768px) {
	:local(.close-btn) {
		transform: translate(-50%, 0%);
	}
}

@media screen and (max-width: 400px) {
	:local(.form-note) {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 360px) {
	:local(.form) {
		width: 290px;
	}
}

@media screen and (max-width: 340px) {
	:local(.form) {
		width: 270px;
	}

	:local(.form-note) {
		margin-bottom: 20px;
		font-size: 13px;
		white-space: nowrap;
	}
}

// Landscape styles
@media screen and (min-width: 500px) and (max-height: 460px) and (orientation : landscape) {
	:local(.form-wrapper) {
		width: 320px;
	}

	:local(.form-entry) {
		padding-top: 0;
		height: 100vh;
		overflow: auto;
	}

	:local(.form-entry-bg) {
		width: 100%;
		height: auto;
	}

	:local(.form) {
		transform: translate(-50%, 25%);
	}

	:local(.close-btn) {
		transform: translate(100%, 0%);
	}
}