@use "../../react-components/styles/theme.scss";
@import "shared";

:local(.ui) {
  @extend %default-font;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}

body.vr-mode {
  :local(.ui) {
    pointer-events: auto;
  }
}

:local(.interstitial) {
  @extend %default-font;
  background-color: theme.$overlay-bg-color;
  color: theme.$overlay-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  div {
    @extend %action-button;
    width: 250px;
  }
}

:local(.avatar-editor) {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: theme.$background1-color;
  z-index: 11;
  pointer-events: auto;
}

:local(.quiz-container) {
  width: 500px;
  position: absolute;
  transition: all 0.3s;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: flex-start;
  padding: 48px;
  gap: 24px;
}

:local(.quiz-container):local(.sz) {
  background: rgba(6, 8, 97, 0.8);
  border: 3px solid #0046cf;
  box-shadow: 0px 1px 24px rgba(98, 0, 238, 0.14), 0px 4px 20px rgba(7, 11, 46, 0.2),
    inset 0px 4px 10px rgba(0, 255, 106, 0.6), inset 3px -4px 7px rgba(255, 73, 201, 0.45),
    inset 0px -14px 19px rgba(98, 0, 238, 0.6);
  border-radius: 42px;
}

:local(.promocode-block) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 48px;
  gap: 20px;

  position: absolute;
  width: 500px;

  background: rgba(36, 56, 73, 0.8);
  /* Yel */
  box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 42px;
  @media screen and (max-width: 600px) {
    right: -24px;
    top: -10%;
  }

  :local(.close-btn) {
    right: -44px;
    top: -7%;

    // padding-left: 20px;
    // padding-top: 14px;
    @media screen and (max-width: 600px) {
      right: -24px;
      top: -10%;
    }
  }
}

:local(.sberzvuk-block) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 48px;
  gap: 20px;
  user-select: none; // Prevent accidental selection

  // display: flex;
  // flex-direction: column;
  // justify-content: center;

  position: absolute;
  width: 500px;

  background: rgba(6, 8, 97, 0.8);
  box-shadow: 0px 4px 24px #2400ff;
  backdrop-filter: blur(10px);
  border-radius: 42px;
  @media screen and (max-width: 600px) {
    width: 85%;
  }

  :local(.close-btn) {
    right: -44px;
    top: -7%;

    // padding-left: 20px;
    // padding-top: 14px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      right: -24px;
      top: -10%;
    }
  }

  img {
    position: relative;
    top: -118px;
  }

  :local(.present-text) {
    margin-top: -130px;
  }
  :local(.subscribe-btn) {
    color: #6200ee;
    &:hover {
      color: #ac18f2;
    }
  }

  :local(.more-link):hover {
    color: #ac18f2;
  }
  :local(.subscribe-sber) {
    padding-top: 25px;
  }
  :local(.sber-offer) {
    font-size: 30px;
    line-height: 16px;
  }
}

:local(.sber-block) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 48px;
  gap: 20px;
  user-select: none; // Prevent accidental user selection

  background: rgba(36, 56, 73, 0.8);
  box-shadow: 0px 4px 16px #22df7a;
  backdrop-filter: blur(10px);
  border-radius: 42px;

  :local(.close-btn) {
    right: -48px;
    top: -40px;
    padding: 0;
    // width: 42px;
    // height: 42px;
    // transform-origin: center;
    // transform: translate(100%, -50%) rotate(45deg);
    cursor: pointer;

    // padding-left: 20px;
    // padding-top: 14px;
    // @media screen and (max-width: 600px) {
    //   transform: translate(0%, -100%) rotate(45deg);
    // }
  }

  // :local(.close-btn-line) {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   border-radius: 3px;
  //   width: 55%;
  //   height: 4px;
  //   transform: translate(-50%, -50%) scale3d(1, 1, 1);
  //   transition: transform 0.35s ease-out;
  //   background-color: #fff;
  //   transform-origin: center;

  //   &:last-child {
  //     transform: translate(-50%, -50%) rotate(90deg) scale3d(1, 1, 1);
  //   }
  // }

  // :local(.close-btn:hover), :local(.close-btn:focus) {
  //   :local(.close-btn-line) {
  //     transform: translate(-50%, -50%) scale3d(0.63, 1, 1);

  //     &:last-child {
  //       transform: translate(-50%, -50%) rotate(90deg) scale3d(0.63, 1, 1);
  //     }
  //   }
  // }

  :local(.subscribe-btn) {
    color: #333;
  }

  img {
    width: 300px;
    top: -170px;
  }

  :local(.present-text) {
    margin-top: -225px;
  }

  :local(.subscribe-sber) {
    @media screen and (max-width: 600px) {
      line-height: 1.2;
    }
  }

  &.statue {
    :local(.present-text) {
      margin-top: 0;
      // width: 44%;
    }
  }
}

:local(.sber-logo) {
  padding-top: 25px;
  padding-bottom: 18px;

  img {
    width: 225px;
  }
}

:local(.subscribe-sber) {
  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

:local(.sber-offer) {
  font-weight: 600;
  font-size: 36px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}

:local(.subscribe-btn) {
  background: #ffffff;
  border-radius: 51.3266px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  width: 250px;
  text-align: center;
  height: 56px;
  padding-top: 9px;
  margin-top: 28px;
  color: limegreen;

  &:hover {
    color: lawngreen;
  }
}

:local(.more-link) {
  text-decoration: underline;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;

  &:hover {
    color: limegreen;
    text-decoration: none;
  }
}

:local(.litres-img) {
  position: relative;
  z-index: 10;
  top: -96px;
}

:local(.present-text) {
  margin-top: -80px;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 100%;
  text-align: center;

  color: #ffffff;
}

:local(.promo-input) {
  height: 48px;

  border: 1px solid #dadee7;
  border-radius: 8px;
  background-color: transparent;

  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ff4c00;
}

:local(.descr-text) {
  font-weight: 400;
  font-size: 20px;
  line-height: 104.9%;
  color: #ffffff;
}
:local(.full-descr) {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  color: #ffffff;
}
:local(.text-auth) {
  font-weight: 700;
  > a {
    color: #ff4c00;
  }
}

:local(.link-btn) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 44px;

  background: #ff4c00;
  border-radius: 32px;
  padding: 8px 24px;

  font-weight: 400;
  font-size: 18px;
  line-height: 17px;

  color: #ffffff;
}

:local(.icon-question) {
  position: relative;
  left: -38px;
  top: 111px;
  z-index: 10;
  height: 135px;
}

:local(.out) {
  background: rgba(255, 255, 255, 0);
}
:local(.close-btn) {
  // border-radius: 24px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  // background: rgba(38, 62, 84, 0.8);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 19px 38px rgba(33, 38, 44, 0.15);
  // backdrop-filter: blur(6px);
  // padding-left: 18px;
  // padding-top: 10px;
  z-index: 100;
  // color: #ffffff;
  user-select: none; // Prevent accidental text selection

  height: 32px;
  width: 32px;
  fill: rgba(38, 62, 84, 0.8);

  .border,
  .stroke {
    stroke: #ffdf67;
  }

  &.green {
    .border,
    .stroke {
      stroke: #18935b;
    }
  }
}

// TODO Cleanup dublicated styles
// src/custom/Quiz/assets/stylesheets/quiz-ui.scss

// :local(.quiz-title) {
//   padding: 48px;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 110%;

//   color: #ffd63c;
//   background: rgba(38, 62, 84, 0.8);
//   border: 3px solid #ffdf67;
//   box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
//   backdrop-filter: blur(10px);
//   border-radius: 42px;
// }

// :local(.quiz-counter-container) {
//   flex: none;
//   position: fixed;
//   top: 38px;
//   background: rgba(38, 62, 84, 0.8);
//   border-radius: 32px;
//   height: 64px;
//   width: 112px;
//   margin-left: 125px;
//   cursor: pointer;
// }

// :local(.icon-quiz) {
//   background: no-repeat 15px center url("../images/custom/Question.svg");
//   height: 64px;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: flex-end;
//   flex-direction: column;
// }

// :local(.counter-quiz) {
//   font-family: "SB Sans Interface", sans-serif;
//   font-size: 20px;
//   color: #ffffff;
//   line-height: 24.88px;
//   padding-right: 15px;
//   letter-spacing: 2px;
// }
// :local(.curr-quiz) {
//   font-weight: 600;
// }

:local(.answers-container) {
  width: 100%;
  > div {
    text-align: center;
    margin: 5px;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 18px;
    gap: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 110%;

    &:hover {
      cursor: pointer;
      font-weight: 600;
      background: rgba(38, 62, 84, 0.9);
      border: 3px solid #ffdf67;
      box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), inset 0px 0px 24px #ffdf67;
      backdrop-filter: blur(10px);
      border-radius: 42px;
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
}

:local(.answer-true) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 255, 106, 0.4), rgba(0, 255, 106, 0.4)), rgba(43, 66, 95, 0.1);
  border: 3px solid #00ff6a;
  box-shadow: 0px 0px 16px rgba(0, 255, 106, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 42px;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 110%;

  color: #ffffff;

  text-shadow: 0px 0px 4px rgba(0, 255, 106, 0.9);
}

:local(.answer-false) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(255, 0, 46, 0.4), rgba(255, 0, 46, 0.4)), rgba(43, 66, 95, 0.1);
  border: 3px solid #ff002e;
  box-shadow: 0px 0px 16px #ff002e, 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 42px;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 110%;

  color: #ffffff;

  text-shadow: 0px 0px 4px rgba(255, 0, 77, 0.9);
}

:local(.answer-base) {
  color: #ffdf67;

  background: rgba(38, 62, 84, 0.8);
  border: 3px solid #ffdf67;
  box-shadow: 0px 4px 16px rgba(235, 255, 0, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 42px;
}

:local(.answer-base):local(.sz) {
  color: #ffffff;
  background: rgba(6, 8, 97, 0.8);
  border: 3px solid #0046cf;
  box-shadow: 0px 4px 16px rgba(0, 70, 207, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 42px;

  &:hover {
    font-weight: 600;
    box-shadow: 0px 4px 16px rgba(0, 70, 207, 0.7), 0px 0px 48px rgba(0, 0, 0, 0.15), inset 0px 0px 20px #02afe5;
  }
}

:local(.sber-block) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 48px;
  gap: 20px;
  width: 45vw;
  > img {
    width: 225px;
  }

  background: rgba(36, 56, 73, 0.8);
  box-shadow: 0px 4px 16px #22df7a;
  backdrop-filter: blur(10px);
  border-radius: 42px;
  @media screen and (max-width: 600px) {
    padding: 11px 14px;
    width: 95%;
  }
}

:local(.close-btn) {
  right: -44px;
  top: -7%;

  // padding-left: 20px;
  // padding-top: 14px;
  @media screen and (max-width: 600px) {
    right: -6px;
    top: -12%;
  }
}

:local(.present-text) {
  // margin-top: -225px;
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

:local(.sber-logo) {
  padding-top: 25px;
  padding-bottom: 18px;

  @media screen and (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

:local(.subscribe-sber) {
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  line-height: 3vw;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
}

:local(.subscribe-sber-marked) {
  color: #f1c2fb;
}

:local(.sber-offer) {
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

:local(.subscribe-btn) {
  background: #ffffff;
  border-radius: 51.3266px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  width: 250px;
  text-align: center;
  height: 56px;
  padding-top: 9px;
  margin-top: 28px;
  color: limegreen;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
}

:local(.more-link) {
  text-decoration: underline;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;

  &:hover {
    color: limegreen;
    text-decoration: none;
  }
}
