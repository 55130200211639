:local(.star-constructor-container) {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	background-color: #000;
	min-width: 320px;
	overflow-x: hidden;
	pointer-events: all;
	cursor: auto;
}

:local(.main) {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	padding: 0 10px;
	width: 100%;
	max-width: (1600px + 10px*2);
	height: 100vh;
}

:local(.main__slider) {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 1 100%;
	max-height: calc(100vh - 110px);
}

:local(.main__slider) iframe {
	width: 100%;
	height: 100%;
	border: none;
}

:local(.main__arrow-wrapper) {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 1 10%;
}

:local(.main__phumb) {
	flex: 0 0 100%;
}

:local(.main__img) {
	width: 100%;
	height: auto;
	object-fit: contain;
	max-height: calc(100vh - 110px);
	transform: translateY(5px);
}

:local(.main__share) {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

:local(.closeBtn) {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(-50%, 50%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

:local(.closeBtnFiller) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
  transition: opacity 0.35s linear;
}

:local(.closeBtnFillerDefault) {
  z-index: 1;
  opacity: 1;
}

:local(.closeBtnFillerActive) {
  z-index: 2;
  opacity: 0;
}

:local(.closeBtn):hover,
:local(.closeBtn):focus {
  background: transparent;
  border: none;
  outline: none;

  :local(.closeBtnFillerDefault) {
    opacity: 0;
  }

  :local(.closeBtnFillerActive) {
    opacity: 1;
  }
}

:local(.star-btn) {
	position: relative;
	flex: 1 1 auto;
	overflow: hidden;
	outline: none;
	border-radius: 20px;
	border: none;
	height: 100px;
	cursor: pointer;
	background-color: #303030;

	& + & {
		margin-left: 40px;
	}

	.star-btn__img-text {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		line-height: 1;
		transition: opacity 0.35s ease-out;
		pointer-events: none;
		object-fit: contain;
	}

	.star-btn__img-text--1 {
		z-index: 1;
		opacity: 1;
	}
	.star-btn__img-text--2 {
		z-index: 2;
		opacity: 0;
	}

	&:hover,
	&:focus {
		.star-btn__img-text--1 {
			opacity: 0;
		}

		.star-btn__img-text--2 {
			opacity: 1;
		}
	}
}

@media screen and (max-width: 1200px) {
	:local(.star-btn) {
		height: 60px;
	}

	:local(.main__slider) {
		max-height: calc(100vh - 60px);
		align-items: flex-end;
	}
	:local(.main__img) {
		max-height: calc(100vh - 60px);
	}
}

@media screen and (max-width: 800px) {
	:local(.main__share) {
		flex-direction: column;
		justify-content: flex-start;
	}

	:local(.star-btn) {
		flex: initial;
		width: 100%;

		& + & {
			margin-left: 0;
			margin-top: 20px;
		}
	}
}