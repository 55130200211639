@import url('./assets/fonts/FridayCondensed.css');

:local(.Modal) {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vh;
  min-height: 660px;
  max-width: 100vw;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100%;
  }
}

:local(.ModalOverlay) {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: -1;
  background-color: rgba(#000, 0.5);
}

:local(.closeBtn) {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(100%, -100%);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

:local(.closeBtnFiller) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
  transition: opacity 0.35s linear;
}

:local(.closeBtnFillerDefault) {
  z-index: 1;
  opacity: 1;
}

:local(.closeBtnFillerActive) {
  z-index: 2;
  opacity: 0;
}

:local(.closeBtn):hover,
:local(.closeBtn):focus {
  background: transparent;
  border: none;
  outline: none;

  :local(.closeBtnFillerDefault) {
    opacity: 0;
  }

  :local(.closeBtnFillerActive) {
    opacity: 1;
  }
}

:local(.shareBtn) {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  flex: 1 1 auto;
  border-radius: 52px;
  margin: 0 auto;
  padding: 20px 60px;
  max-width: 100%;
  background-image: linear-gradient(-68deg, #ca9c47 0%, #f3dc60 100%);
  cursor: pointer;
  text-align: center;
  font-family: "Friday Condensed";
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
  text-decoration: none;
  transform: translateY(50%);

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    cursor: pointer;
    transform: translateY(-4px);
    transition: transform 0.35s ease-out;
    background-color: #a7802d;
  }

  &:hover,
  &:focus {
    &::before {
      transform: translateY(0);
    }
  }
}


@media screen and (min-width: 1850px) and (min-height: 780px) {
  :local(.Modal) {
    min-height: 800px;
  }
  :local(.closeBtn) {
    transform: translate(0%, 0%);
  }
}
@media screen and (min-width: 2200px) and (min-height: 960px) {
  :local(.Modal) {
    min-height: 940px;
  }
}

@media screen and (max-width: 965px) {
  :local(.closeBtn) {
    transform: translate(50%, -150%);
  }

  :local(.shareBtn) {
    padding: 20px 60px;
  }
}

@media screen and (max-width: 665px) {
  :local(.shareBtn) {
    padding: 15px 30px;
    font-size: 22px;
  }
}

@media screen and (max-width: 600px) {
  :local(.Modal) {
    width: 100vw;
    max-width: 100vw;
  }

  :local(.closeBtn) {
    transform: translate(0%, -150%);
  }
}

@media screen and (max-width: 500px) {
  :local(.Modal) {
    width: 94vw;
    height: 92vh;
    min-height: 570px;
    max-height: 100vh;
    max-width: 100vw;
  }

  :local(.closeBtn) {
    transform: translate(0%, -100%);
  }
}

@media screen and (max-width: 500px) and (max-height: 850px) {
  :local(.Modal) {
    height: 89vh;
    min-height: 500px;
    max-height: calc(100vh - 150px);
    max-width: 100vw;

    iframe {
      transform: translateY(3vh);
    }
  }

  :local(.closeBtn) {
    -webkit-transform: translate(0%, calc(-100% + 3vh));
    -moz-transform: translate(0%, calc(-100% + 3vh));
    transform: translate(0%, calc(-100% + 3vh));
  }
}

// Landscape styles
@media screen and (min-width: 500px) and (max-height: 460px) and (orientation : landscape) {
  :local(.Modal) {
    height: 100vh;
    min-height: initial;

    iframe {
      transform: translateY(0);
    }
  }

  :local(.closeBtn) {
    transform: translate(100%, 0%);
  }
}