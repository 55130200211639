:local(.collectWrapper) {
  // @media (max-width: 768px) {
  //   left: 16%;
  //   scale: 0.6;
  //   justify-content: center;
  //   display: flex;
  // }

  @media (max-width: 768px) {
    justify-content: center;
    display: flex;
    left: 41%;
    scale: 0.8;
  }
  @media (max-width: 600px) {
    left: 22%;
    scale: 0.6;
    justify-content: center;
    display: flex;
  }
  @media (max-width: 400px) {
    left: 8%;
    scale: 0.5;
    justify-content: center;
    display: flex;
  }
  @media (max-width: 330px) {
    left: -6%;
    scale: 0.4;
    justify-content: center;
    display: flex;
  }

  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  user-select: none;

  > * { 
    @media (max-width: 330px) {
    margin: 0 3px;
  }

    @media (max-width: 375px) {
      margin: 0 5px;
    }
    @media (max-width: 768px) {
      margin: 0 10px;
    }
    @media (max-width: 560px) {
      margin: 0 10px;
    }

    margin: 0 50px;
  }
}
