@use "../../react-components/styles/theme.scss";

:local(.preview) {
  position: relative;
  overflow: hidden;
  background: theme.$tile-bg-color;
  border-radius: 20px;

  .loader {
    position: absolute;
    left: calc(50% - 4.3em);
    top: calc(50% - 4.5em);
  }
  .error {
    color: theme.$text2-color;
    text-align: center;
    padding: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    white-space: pre-wrap;
    box-sizing: border-box;
    width: 100%;
  }
  .error-icon {
    width: 16px;
    height: 16px;
    transform: translateY(2px);
    margin: 0 0.3em;
  }
}
