@use "../styles/theme.scss";

:local(.modal) {

  top:auto;
  bottom: auto;
  position: absolute;

  // position: relative;
  display: flex;
  flex-direction: column;
  height: max-content;
  //background-color: theme.$background1-color;
  //border: 1px solid theme.$border1-color;
  //border-radius: theme.$border-radius-regular;
  margin: 24px;
  z-index: 1;
  width: 100%;
  max-width: 460px;

  background: rgba(255, 255, 255, 0.64);
  border: 1px solid #D4E4EB;
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 32px;
}

:local(.header) {
  display: flex;
  height: 130px;
  //border-bottom: 1px solid theme.$border1-color;
  font-family: "SB Sans Display Bold", sans-serif;
  color: #263E54;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 46px;
}

:local(.before-title) {
  //position: absolute;
  left: 0;
  margin-left: 16px;
  margin-top: 30px;

  font-family: 'SB Sans Display Thin', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #263E54;

  opacity: 0.6;
}

:local(.after-title) {
  position: absolute;
  right: 0;
  margin-right: 16px;
}

:local(.content) {
  min-height: 200px;
  display: flex;
  flex: 0;
  flex-direction: column;
}

:local(.sm-fullscreen) {
  @media(max-width: theme.$breakpoint-lg - 1), (max-height: theme.$breakpoint-vr - 1) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-width: 0;
    border-radius: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    justify-content: center;
    align-items: center;

    :local(.content) {
      overflow-y: auto;
    }
  }
}